class AdCnmForm extends HTMLElement {
    constructor() {
        super();
    }


    connectedCallback() {
        this.submitButton = this.querySelector('button[type=submit]');
        this.submitButton?.addEventListener('click', (event) => this.Submit(event));
        this.form = this.querySelector('form');
    }

    Submit(event) {
        event.preventDefault();
        window.dataLayerFormSubmit("Criteria Not Met Form",
            document.title,
            "",
            "Regular",
            "",
            "",
            this.querySelector('[name=Product]'));
        getReCaptchaTokenForForm(this.form);
        this.submitButton?.classList.add('disabled');
        this.submitButton.setAttribute('disabled', true);
    }
}

export default AdCnmForm;