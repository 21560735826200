class AdHeader extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        if (this.dataset.dynamicSecondary != 'True') {
            return;
        }
        const secondaryNavList = this.querySelector('.js-header-secondary-list');
        const mobileNavList = this.querySelector('.js-header-mobile-list');

        const headings = document.querySelectorAll('.h2, h2');
        headings.forEach((heading, index) => {
            const headingId = heading.id || `page-anchor-${index + 1}`;
            heading.id = headingId;

            const anchorText = document.createElement('span');
            anchorText.textContent = heading.textContent;

            const anchor = document.createElement('a');
            anchor.classList.add('body-2');
            anchor.classList.add('nav-link');
            anchor.href = `#${headingId}`;
            anchor.insertAdjacentElement('beforeend', anchorText);
            anchor.addEventListener('click', e => {
                e.preventDefault();
                heading.scrollIntoView({
                    behavior: 'smooth'
                });
            });

            const listItem = document.createElement('li');
            listItem.classList.add('nav-item');
            listItem.appendChild(anchor);

            const mobileListItem = listItem.cloneNode(true);

            secondaryNavList.appendChild(listItem);
            mobileNavList.appendChild(mobileListItem);

            secondaryNavList.querySelectorAll('a').forEach((element) => {
                element.addEventListener('click', (event) => this.headerPushToDataLayer(event));
            });

            mobileNavList.querySelectorAll('a').forEach((element) => {
                element.addEventListener('click', (event) => this.headerPushToDataLayer(event));
            });
        });
    }

    headerPushToDataLayer(event) {
        window.dataLayerPush({
            'event': 'e_navigationClick',
            'menuType': 'Header',
            'menuItemName': event.target.innerText
        });
    }
}

export default AdHeader