import intlTelInput from 'intl-tel-input';

class AdForm extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.blockId = this.dataset.blockId;
        this.piiFields = ["firstname", "lastname", "email", "zipcode", "phone"];
        this.degreeSelect = this.querySelector(`#degree-${this.blockId}`);
        this.aosSelect = this.querySelector(`#aos-${this.blockId}`);
        this.programSelect = this.querySelector(`#programs-${this.blockId}`);
        this.additionalQuestion = this.querySelector(`#additional-${this.blockId}`);
        this.additionalQuestionType = this.querySelector(`#additional-type-${this.blockId}`);
        this.continueButton = this.querySelector('.js-continue');
        this.backButton = this.querySelector('.js-back');
        this.submitButton = this.querySelector('.js-submit');
        this.areaOfStudyCategory = this.querySelector(`#aos-input-${this.blockId}`);
        this.degreeCategory = this.querySelector(`#degree-input-${this.blockId}`);
        this.form = this.querySelector('form');
        this.section1 = this.querySelector(`#section-1-${this.blockId}`);
        this.section2 = this.querySelector(`#section-2-${this.blockId}`);
        this.reCaptchaInput = this.querySelector(`#g-recaptcha-response-${this.blockId}`);
        this.countryInput = this.querySelector(`#country-${this.blockId}`);
        this.stateInput = this.querySelector(`#state-${this.blockId}`);
        this.cityInput = this.querySelector(`#city-${this.blockId}`);

        this.isMultistep = this.dataset.multistep.toLowerCase() == "true";
        this.formType = this.isMultistep ? "multistep" : "regular";
        this.programType = this.dataset?.programType ?? "regular";

        this.filterPrograms(this.dataset.aosFilter, this.dataset.degreeFilter);
        this.showAdditionalQuestion();
        this.degreeSelect?.addEventListener('change', (event) => this.filterPrograms(this.aosSelect.value, this.degreeSelect.value));
        this.aosSelect?.addEventListener('change', (event) => this.filterPrograms(this.aosSelect.value, this.degreeSelect.value));
        this.programSelect?.addEventListener('change', (event) => this.showAdditionalQuestion());
        this.continueButton?.addEventListener('click', (event) => this.forwardStep(event));
        this.backButton?.addEventListener('click', (event) => this.backStep());
        this.submitButton.addEventListener('click', (event) => this.validateAndSubmit(event));

        if (this.dataset.isInternational.toLowerCase() == 'true') {
            var phoneInput = this.querySelector(`#phone-${this.blockId}`);
            var ipCountry = this.getLocationCountry() || "ca";
            intlTelInput(phoneInput, {
                loadUtilsOnInit: () => import("intl-tel-input/utils"),
                countrySearch: false,
                nationalMode: true,
                separateDialCode: true,
                initialCountry: ipCountry,
                autoPlaceholder: "polite",
                utilsScript: "/Scripts/intl-tel-input/js/utils.js?20",
                countryOrder: ["ca", "us"]
            });
        }
        this.querySelectorAll('input').forEach((input) => {
            input.addEventListener('change', (event) => {
                this.updateInput(event);
            });
        });
        this.querySelectorAll('select').forEach((input) => {
            input.addEventListener('change', (event) => {
                this.updateInput(event);
            });
        });

        this.validateOnBlur('input[required]');
        this.setLocation();
    }

    updateInput(event) {
        var step = this.isMultistep && !this.section2?.classList?.contains('hidden') ? 2 : 1;
        var value = this.piiFields.includes(event.target?.name?.toLowerCase()) ? "PII" : event?.target?.value;
        window.dataLayerPush({
            "event": "e_formFieldValue",
            "fieldName": event.target?.name,
            "formName": this.form?.name,
            "formStepNumber": step,
            "fieldValue": value,
            "formType": this.formType,
            "programType": this.programType
        });
    }

    trackInput(input, inputType) {
        if (this.piiFields.includes(input?.name?.toLowerCase())) {
            return;
        }

        if (inputType == "input") {
            window.dataLayerPush({
                'event': 'e_formFieldInput',
                'fieldName': input?.name,
                'formName': this.form?.name,
                'fieldValue': input?.value,
                'formSourceCode': "",
                'formType': this.formType,
                'programType': this.programType
            })
            return;
        }
        window.dataLayerPush({
            'event': 'e_formDropdown',
            'fieldName': input?.name,
            'formName': this.form?.name,
            'fieldValue': input?.value,
            'formSourceCode': "",
            'formType': this.formType
        })
    }

    setLocation() {
        if (!optimizely) {
            console.error("adForm->setLocation: optimizely object missing");
            return;
        }

        var visitor = optimizely.get('visitor');

        if (!visitor) {
            console.error("adForm->setLocation: optimizely visitor object missing");
            return;
        }

        return visitor;
    }

    getVisitor() {
        return window["optimizely"].get("visitor");
    }

    getLocationCountry() {
        var visitor = this.getVisitor();
        return visitor.location?.country;
    }

    setLocation() {
        var visitor = this.getVisitor();

        this.countryInput.value = visitor.location?.country;
        this.stateInput.value = visitor.location?.region;
        this.cityInput.value = visitor.location?.city;
    }

    validateAndSubmit(event) {
        event.preventDefault();

        this.submitButton.classList.add('disabled');
        this.submitButton.setAttribute('disabled', true);

        var valid = true;
        this.form.querySelectorAll('[required]').forEach((required) => {
            if (!this.validate(required)) {
                valid = false;
            }
        });

        if (!valid) {
            this.submitButton.classList.remove('disabled');
            this.submitButton.removeAttribute('disabled');
            return;
        }

        this.querySelectorAll('input').forEach((input) => {
            if (input.classList.contains('hidden')) {
                return;
            }

            if (input.type.toLowerCase() == "hidden") {
                return;
            }

            if (input.type.toLowerCase() == "radio"
                && !input.checked) {
                return;
            }

            if (input.type.toLowerCase() == "checkbox"
                && !input.checked) {
                return;
            }


            this.trackInput(input, "input");
        });

        this.querySelectorAll('select').forEach((input) => {
            this.trackInput(input, "dropdown");
        });
        var step = this.isMultistep && !this.section2?.classList?.contains('hidden') ? 2 : 1;
        if (step == 2) {
            window.dataLayerPush({
                "event": "e_formPageSubmit",
                "formName": this.form?.name,
                "formStepNumber": step,
                "degreeSelected": this.degreeSelect?.value,
                "aosSelected": this.aosSelect?.value,
                "programSelected": this.programSelect?.value
            });
        }

        window.dataLayerPush({
            "event": "e_formSubmit",
            "formName": this.form?.name,
            "formSourceCode": "",
            "formType": this.formType,
            "degreeSelected": this.degreeSelect?.value,
            "aosSelected": this.aosSelect?.value,
            "programSelected": this.programSelect?.value
        });
        getReCaptchaTokenForForm(this.form);
    }

    validateOnBlur(element) {
        this.querySelectorAll(element).forEach((element) => element.addEventListener('blur', (event) => {
            this.validate(event.target)
        }));
    }

    validate(element) {
        if (element.checkValidity()) {
            element.classList.add('valid');
            element.classList.remove('invalid');
            return true;
        }
        element.classList.remove('valid');
        element.classList.add('invalid');
        window.dataLayerPush({
            "event": "e_errorMessage",
            "error": element?.validationMessage,
            "formType": this.formType,
        });
        return false;
    }

    validateSelect(element) {
        if (!element.value) {
            element.classList.remove('valid');
            element.classList.add('invalid');
            window.dataLayerPush({
                "event": "e_errorMessage",
                "error": element?.validationMessage,
                "formType": this.formType,
            });
            return false;
        }
        element.classList.add('valid');
        element.classList.remove('invalid');
        return true;
    }

    forwardStep(event) {
        event.preventDefault();
        let valid = true;
        this.section1.querySelectorAll('select[required]').forEach((select) => {
            if (!this.validateSelect(select)) {
                valid = false;
            }
        });

        this.section1.querySelectorAll('input[required]').forEach((input) => {
            if (!this.validate(input)) {
                valid = false;
            }
        });

        if (!valid) {
            return;
        }
        window.dataLayerPush({
            "event": "e_formPageSubmit",
            "formName": this.form?.name,
            "formStepNumber": 1,
            "degreeSelected": this.degreeSelect?.value,
            "aosSelected": this.aosSelect?.value,
            "programSelected": this.programSelect?.value
        });

        this.section1.classList.add('hidden');
        this.section2.classList.remove('hidden');
    }

    backStep() {

        this.section1.classList.remove('hidden');
        this.section2.classList.add('hidden');
    }

    filterPrograms(aos, degree) {
        if (!aos && !degree) {
            return;
        }
        this.programSelect.value = this.programSelect?.dataset?.defaultValue ?? "";

        this.programSelect.querySelectorAll('option').forEach((option) => {
            if (this.checkAOS(aos, option) && this.checkDegree(degree, option)) {
                option.classList.remove('hidden');
                return;
            }
            option.classList.add('hidden');
        });

        this.programSelect.querySelectorAll('optgroup').forEach((optGroup) => {
            if (!this.checkAOS(aos, optGroup)) {
                optGroup.classList.add('hidden');
                return;
            }

            if (optGroup.querySelectorAll('option.hidden').length == optGroup.querySelectorAll('option').length) {
                optGroup.classList.add('hidden');
                return;
            }

            optGroup.classList.remove('hidden');
        });

        this.filterAreasOfStudy(degree);
        this.filterDegrees(aos);
    }

    filterAreasOfStudy(degree) {
        if (!this.aosSelect) {
            return;
        }

        if (!degree) {
            this.aosSelect.querySelectorAll('option').forEach((option) => {
                option.classList.remove('hidden');
            });
            return;
        }

        var activePrograms = [...this.programSelect.querySelectorAll('option')];
        var activeAreasOfStudy = [];
        activePrograms.forEach((program) => {
            if (activeAreasOfStudy.includes(program.dataset.aos)) {
                return;
            }

            if (program.dataset.degree != degree) {
                return;
            }

            activeAreasOfStudy.push(program.dataset.aos);
        });
        this.aosSelect.querySelectorAll('option').forEach((option) => {
            if (activeAreasOfStudy.includes(option.value)) {
                option.classList.remove('hidden');
                return;
            }
            option.classList.add('hidden');
        });
    }

    filterDegrees(aos) {
        if (!this.degreeSelect) {
            return;
        }

        if (!aos) {
            this.degreeSelect.querySelectorAll('option').forEach((option) => {
                option.classList.remove('hidden');
            });
            return;
        }

        var activePrograms = [...this.programSelect.querySelectorAll('option')];
        var activeDegrees = [];
        activePrograms.forEach((program) => {
            if (activeDegrees.includes(program.dataset.degree)) {
                return;
            }

            if (program.dataset.aos != aos) {
                return;
            }

            activeDegrees.push(program.dataset.degree);
        });
        this.degreeSelect.querySelectorAll('option').forEach((option) => {
            if (activeDegrees.includes(option.value)) {
                option.classList.remove('hidden');
                return;
            }
            option.classList.add('hidden');
        });
    }

    checkAOS(aos, option) {
        if (!aos) {
            return true;
        }

        if ((option.dataset.aos == aos || option.dataset.aos == 'n/a')) {
            return true;
        }

        return false;
    }

    checkDegree(degree, option) {
        if (!degree) {
            return true;
        }

        if ((option.dataset.degree == degree || option.dataset.degree == 'n/a')) {
            return true;
        }

        return false;
    }

    showAdditionalQuestion() {
        var selectedOption = this.programSelect.options[this.programSelect.selectedIndex];
        this.areaOfStudyCategory.value = selectedOption.dataset.aos;
        this.degreeCategory.value = selectedOption.dataset.degree;

        if (!selectedOption.dataset.questionKey) {
            this.additionalQuestion.classList.add('hidden');
            this.additionalQuestion.removeAttribute('required');
            this.additionalQuestionType.value = "";
            return;
        }

        this.additionalQuestion.required = true;
        this.additionalQuestion.classList.remove('hidden');
        this.additionalQuestion.querySelectorAll('option').forEach((option) => {
            if (option.dataset.questionKey != selectedOption.dataset.questionKey) {

                option.removeAttribute('selected');
                option.classList.add('hidden');
                return;
            }

            if (option.dataset.selectionId) {
                option.setAttribute('selected', true);
                this.additionalQuestionType.value = option.dataset.questionType;
            }

            option.classList.remove('hidden');
            return;
        });
    }
}

export default AdForm;